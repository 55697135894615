import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import { headerImg, logo } from "../Assets/images/Image";
import { Link } from "react-router-dom";
import Search from "../components/Search";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Dropdown } from "rsuite";
const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState([]);

  const [divTitle, setDivTitle] = useState([]);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const navbarStyle = {
    backgroundImage: `url(${headerImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  useEffect(() => {
    getCompanyName();
  }, []);
  const getCompanyName = async () => {
    const ValidateResponse = (data) => (Array.isArray(data) ? data : []);
    try {
      const res = await axios.get(
        "https://pharmapcdfranchise.co.in/medicine_admin/api/categorybycomp"
      );
      const data = ValidateResponse(res.data.company);
      const divData=ValidateResponse(res.data.division)
      setData(data);
      setDivTitle(divData);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal />

      <nav
        className="navbar navbar-expand-lg bg-body-tertiary py-2 shadow position-sticky"
        style={navbarStyle}
      >
        {/* <LazyLoadImage src={headerImg}
        width={100} height={100}
        alt="Image Alt"
      /> */}
        <div className="container d-flex justify-content-between">
          <Link to="/" className="navbar-brand">
            <LazyLoadImage
              src={logo}
              alt=""
              className="nav_logo"
              loading="lazy"
            />
           
          </Link>
        
          <button
            className={`navbar-toggler ${toggle ? "collapsed" : ""}`}
            type="button"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div
            className={`collapse position-relative navbar-collapse me-3 ${
              toggle ? "show" : ""
            }`}
          >
            <div className="drop-blog d-flex my-1 justify-content-evenly">
              <Dropdown title="Companies" trigger="hover">
                {data.map((item, index) => (
                  <Dropdown.Item key={index}>
                    <Link to={item.url}>{item.title}</Link>
                  </Dropdown.Item>
                ))}

                {divTitle.map((item, index) => (
                  <Dropdown.Item key={index}>
                    <Link to={item.url}> {item.title}</Link>
                  </Dropdown.Item>
                ))}
              </Dropdown>
              <div className="blog-btnUpdated">
                <Link to={`blog`}>Blog</Link>
              </div>
            </div>
            <Search />
            <div className="mx-5 m-sm-2">
              <div className="button-align1">
                <button
                  type="button"
                  className="btn btn-primary btn-lg border-0 post-align btn-reduce-right"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModals"
                >
                  Send Enquiry
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
