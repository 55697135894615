import React, { useEffect, useState,useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { parseISO, format } from "date-fns";
import LoaderBlog from "../components/LoaderBlog";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Helmet } from "react-helmet";
import { BlogApi, getBlogApi } from "../services/api";
import Seo from "../Layouts/Seo";

const BlogView = ({ position }) => {
  useEffect(() => {
    window.scrollTo(0, position);
  }, [position]);
  const obj = useParams();
  // console.log(obj, "blog id");
  const idValue = obj.blog;
  const [data, setData] = useState("");
  //console.log(data,'=====================');

  const [loading, setLoading] = useState(false);
  const [isDate, setIsDate] = useState();
  const [blogData, setBlogData] = useState([]);


  useEffect(() => {
    setLoading(true);
    getApiData();
  },[obj, idValue]);

  // const getApiData = async () => {
  //   const validateResponse = (data) => (Array.isArray(data) ? data : []);
  //   try {
  //     const res = await axios.get(getBlogApi(idValue));
  //     const resAll = await axios.get(BlogApi);
  //     const dataAll = validateResponse(resAll.data);

  //     const filteredData = dataAll.filter((item) => item.slug !== idValue);
  //     setData(res?.data);
  //     setBlogData(filteredData);
  //     const dateObject = parseISO(res?.data?.CreatedAt);
  //     const formattedDate = format(dateObject, "MMM dd, yyyy");
  //     setIsDate(formattedDate);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  const getApiData = useCallback(async () => {
    const validateResponse = (data) => (Array.isArray(data) ? data : []);
    try {
        const res = await axios.get(getBlogApi(idValue));
        const resAll = await axios.get(BlogApi);
        const dataAll = validateResponse(resAll.data);

        const filteredData = dataAll.filter((item) => item.slug !== idValue);
        setData(res?.data || {});
        setBlogData(filteredData);
        const dateObject = parseISO(res?.data?.CreatedAt);
        const formattedDate = format(dateObject, "MMM dd, yyyy");
        setIsDate(formattedDate);
        setLoading(false);
    } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
    }
}, [idValue]);


  if (loading) {
    return <LoaderBlog />;
  }
  return (
    <>
      <Seo
        title="Blog Page | Top PCD Pharma Company - India"
        description="Pharma PCD Franchise is the Top PCD Pharma Company in India.Get 60000+ Products in Pharma PCD Franchise from the list of the best 10 PCD Pharma franchise companies in India."
        type="webapp"
        name="Pharma Company"
        pageUrl={`https://pharmapcdfranchise.co.in/${data?.meta_title}/`}
      />
      <div className="blogparent3">
        <div className="px-5 py-5">
          <div className="row">
            <div className="col-lg-9 col-md-7 col-sm-12">
              <div className="col-12 mx-auto">
                <div className="d-flex justify-content-center ">
                  <LazyLoadImage
                    src={data?.image}
                    className="img-fluid"
                    alt="..."
                  />
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-between pt-5">
                    {/* <div className="fw-bolder">Categories {data?.category}</div> */}
                    <div className="fw-bolder st-post-date">
                      <span>{isDate}</span>
                    </div>
                  </div>
                  <h3 className="h1 fw-bold">{data?.title}</h3>
                  <p className="blog_desc_1">
                    {data?.description ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                    ) : (
                      "No description available"
                    )}
                  </p>
                  {/* <p className="pb-4">
                  {data?.description_front}</p> */}
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-5 col-sm-12 mt-3">
              {blogData.slice(0, 5).map((item, index) => {
                const dateObject = parseISO(item.CreatedAt);
                const formattedDate = format(dateObject, "MMM dd, yyyy");
                return (
                  <Link
                    to={`/${item.slug}/`}
                    className="blog_container"
                    key={index}
                  >
                    <div className="image_container_left">
                      <img src={item.image} width={105} height={105} alt={`img${index}`} />
                    </div>

                    <div className="text_container_right">
                      <h6 className="blog_date_top st-post-date">
                        {formattedDate}
                      </h6>
                      <Link to={`/${item.slug}/`} style={{ color: "grey" }}>
                        <h5 className="fw-normal two-line-text">
                          {item.title}
                        </h5>
                      </Link>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogView;
